export const FeaturesData = [
  {
    mainFeatureName: "LMS ERP",     
    features: [
      {
        "feature_name": "Personalized Learning Paths",
        "infoTip": "Provide customized learning paths based on individual learner preferences, skills, and knowledge gaps.",
        "isAssignStandard": "Yes",
        "isAssignProfessional": "No",
        "isAssignPreminum": "No",
        "isAssignElite": "Yes"
      },
      {
          "feature_name": "Adaptive Learning",
          "infoTip": "Adjust the difficulty level and content of learning modules based on learner performance and progress.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Content Management",
          "infoTip": "Manage and organize learning materials including courses, modules, videos, documents, and assessments.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Authoring Tools",
          "infoTip": "Create and customize interactive and engaging e-learning courses using built-in authoring tools.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Gamification",
          "infoTip": "Incorporate gamified elements such as points, badges, leaderboards, and challenges to enhance learner engagement and motivation.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Assessment and Quizzing",
          "infoTip": "Create and administer assessments, quizzes, and exams to evaluate learner knowledge and comprehension.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Automated Grading",
          "infoTip": "Automatically grade assessments and quizzes to provide instant feedback to learners and save instructor time.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Analytics and Reporting",
          "infoTip": "Track learner progress, engagement, and performance with detailed analytics and reporting dashboards.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Collaboration Tools",
          "infoTip": "Facilitate collaboration and communication among learners, instructors, and peers through discussion forums, chat, and messaging.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Virtual Classroom",
          "infoTip": "Host live virtual classes, webinars, and interactive sessions with video conferencing and screen sharing capabilities.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Mobile Learning",
          "infoTip": "Support mobile access to learning materials and activities through responsive design or dedicated mobile apps.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
        "feature_name": "Content Recommendation",
        "infoTip": "Recommend relevant learning resources and courses based on learner interests, preferences, and performance.",
        "isAssignStandard": "Yes",
        "isAssignProfessional": "No",
        "isAssignPreminum": "No",
        "isAssignElite": "Yes"
      },
      {
          "feature_name": "Language Support",
          "infoTip": "Provide multilingual support to cater to learners from diverse linguistic backgrounds.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Accessibility Features",
          "infoTip": "Ensure accessibility compliance with features such as screen reader compatibility, keyboard navigation, and alt text for images.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Integration with External Systems",
          "infoTip": "Integrate with external systems such as HRIS (Human Resources Information Systems), CRM (Customer Relationship Management), and SIS (Student Information System) for data synchronization and user management.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Certification and Credentialing",
          "infoTip": "Issue certificates, badges, and credentials upon completion of courses or achieving specific learning milestones.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Compliance Training",
          "infoTip": "Deliver and track compliance training programs to ensure regulatory and industry standards are met.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "AI-based Tutoring",
          "infoTip": "Provide personalized tutoring and assistance to learners through AI-powered chatbots or virtual assistants.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Content Security",
          "infoTip": "Implement measures to protect intellectual property rights and ensure the security of learning materials and assessments.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Continuous Improvement",
          "infoTip": "Gather feedback from learners and instructors to continuously improve the LMS platform and learning experience.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }
    ],  
  },
  {
    mainFeatureName: "MARKETING ERP",
    features: [
      {
        feature_name: "CRM",
        infoTip:
          "Cosmos IQ Marketing ERP offers a robust CRM system to manage customer interactions, leads, and sales pipelines effectively",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Advanced Marketing Automation",
        infoTip:
          "Automate marketing campaigns, email marketing, and lead nurturing processes to streamline marketing efforts and enhance customer engagement",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Integrated Sales Management",
        infoTip:
          "Seamlessly manage sales activities, including lead tracking, opportunity management, quoting, and order processing within the ERP system",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Analytical Insights",
        infoTip:
          "Gain valuable insights into marketing performance, sales trends, customer behavior, and ROI through advanced analytics and reporting capabilities",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Campaign Management",
        infoTip:
          "Plan, execute, and track multi-channel marketing campaigns efficiently, including social media, email, advertising, and more, all within the ERP platform",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Targeted Segmentation",
        infoTip:
          "Segment customers based on demographics, behaviors, preferences, and purchase history to create personalized marketing strategies and enhance customer satisfaction",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Inventory and Supply Chain Management",
        infoTip:
          "Optimize inventory levels, streamline procurement processes, and ensure efficient supply chain operations to meet customer demand and reduce costs",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Financial Integration",
        infoTip:
          "Integrate marketing activities with financial data, including budgeting, forecasting, and revenue tracking, to ensure alignment between marketing efforts and overall financial goals",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Scalability and Customization",
        infoTip:
          "Cosmos IQ Marketing ERP offers scalability and customization options to adapt to the evolving needs of your marketing team and business growth",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
      {
        feature_name: "Mobile Accessibility",
        infoTip:
          "Access marketing and sales data on the go with mobile-friendly interfaces and applications, allowing for flexibility and productivity in a fast-paced business environment",
        isAssignStandard: "Yes",
        isAssignProfessional: "No",
        isAssignPreminum: "No",
        isAssignElite: "Yes",
      },
    ],
  },
  {
    mainFeatureName: "PROJECT MANAGEMENT AI",    
    features: [
      {
        "feature_name": "Project Planning",
        "infoTip": "Create and manage project plans, including task scheduling, resource allocation, and dependencies.",
        "isAssignStandard": "Yes",
        "isAssignProfessional": "No",
        "isAssignPreminum": "No",
        "isAssignElite": "Yes"
      },
      {
          "feature_name": "Task Management",
          "infoTip": "Assign tasks to team members, set deadlines, track progress, and monitor task completion.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Resource Management",
          "infoTip": "Allocate resources efficiently, including personnel, equipment, and materials, to ensure optimal utilization and project success.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Budgeting and Cost Management",
          "infoTip": "Set project budgets, track expenses, and manage costs throughout the project lifecycle.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Time Tracking",
          "infoTip": "Record and monitor time spent on project tasks and activities to accurately measure project progress and costs.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Gantt Charts",
          "infoTip": "Visualize project timelines, task dependencies, and milestones using Gantt charts for better planning and communication.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Collaboration Tools",
          "infoTip": "Facilitate collaboration among project team members through discussion forums, messaging, file sharing, and document collaboration.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Document Management",
          "infoTip": "Centralize project-related documents, files, and communications for easy access and reference.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Risk Management",
          "infoTip": "Identify, assess, prioritize, and mitigate project risks to minimize their impact on project objectives.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Issue Tracking",
          "infoTip": "Log, prioritize, and resolve project issues and discrepancies in a timely manner to prevent delays and disruptions.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
        "feature_name": "Change Management",
        "infoTip": "Manage changes to project scope, schedule, and resources effectively, while ensuring stakeholder alignment and approval.",
        "isAssignStandard": "Yes",
        "isAssignProfessional": "No",
        "isAssignPreminum": "No",
        "isAssignElite": "Yes"
      },
      {
          "feature_name": "Reporting and Analytics",
          "infoTip": "Generate reports and dashboards to track project performance, monitor key metrics, and identify areas for improvement.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Resource Forecasting",
          "infoTip": "Forecast resource needs and availability to anticipate potential bottlenecks and proactively address resource constraints.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Agile Project Management",
          "infoTip": "Support Agile methodologies such as Scrum or Kanban, including features like sprint planning, backlog management, and burndown charts.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Portfolio Management",
          "infoTip": "Manage multiple projects simultaneously, prioritize investments, and optimize resource allocation across the project portfolio.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Integration with External Systems",
          "infoTip": "Integrate with other enterprise systems such as accounting, HR, and CRM for seamless data exchange and process automation.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Client Collaboration",
          "infoTip": "Enable collaboration with clients and stakeholders, providing visibility into project progress, deliverables, and milestones.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Mobile Access",
          "infoTip": "Allow project team members to access project information, update tasks, and collaborate on-the-go through mobile devices.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Customization and Extensibility",
          "infoTip": "Customize the ERP system to align with specific project management methodologies, workflows, and business requirements.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Security and Permissions",
          "infoTip": "Implement role-based access control, data encryption, and other security measures to protect sensitive project information and ensure data privacy.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }
    ],
  },
  {
    mainFeatureName: "IT MANAGEMENT ERP",    
    features: [      
        {
            "feature_name": "Asset Management",
            "infoTip": "Track and manage IT assets including hardware, software, licenses, and warranties throughout their lifecycle.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Inventory Management",
            "infoTip": "Maintain inventory levels of IT equipment, components, and supplies to ensure availability and optimize procurement.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Service Desk/Help Desk",
            "infoTip": "Provide a centralized platform for users to report IT issues, request assistance, and track the resolution process.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Incident Management",
            "infoTip": "Capture, categorize, prioritize, and resolve IT incidents efficiently to minimize disruption to business operations.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Change Management",
            "infoTip": "Manage and track changes to IT infrastructure, applications, and configurations in a controlled and systematic manner.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Configuration Management Database (CMDB)",
            "infoTip": "Maintain a comprehensive database of IT assets, configurations, and relationships to support effective change and incident management.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "ITIL Framework Compliance",
            "infoTip": "Align IT service management practices with ITIL (Information Technology Infrastructure Library) best practices for improved efficiency and service quality.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Service Level Agreement (SLA) Management",
            "infoTip": "Define, monitor, and enforce SLAs to ensure that IT services meet agreed-upon performance levels and standards.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Knowledge Base",
            "infoTip": "Capture, organize, and share IT knowledge, best practices, troubleshooting guides, and solutions to facilitate self-service and faster issue resolution.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Remote Monitoring and Management (RMM)",
            "infoTip": "Monitor IT infrastructure, systems, and networks remotely to proactively identify and address potential issues before they escalate.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Patch Management",
            "infoTip": "Automate the deployment of software patches, updates, and security fixes to maintain system integrity and protect against vulnerabilities.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },        
        {
            "feature_name": "Vendor Management",
            "infoTip": "Manage vendor relationships, contracts, and service level agreements (SLAs) to ensure the timely delivery of products and services.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Budgeting and Cost Management",
            "infoTip": "Track IT expenditures, budget allocations, and cost centers to optimize spending and resource allocation.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Compliance and Security Management",
            "infoTip": "Ensure compliance with regulatory requirements and industry standards (e.g., GDPR, HIPAA, PCI DSS) and implement security controls to protect sensitive data and assets.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Disaster Recovery and Business Continuity Planning",
            "infoTip": "Develop and maintain plans and procedures to recover IT systems and services in the event of a disruption or disaster.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Reporting and Analytics",
            "infoTip": "Generate reports, dashboards, and key performance indicators (KPIs) to monitor IT performance, identify trends, and make data-driven decisions.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Integration with IT Systems",
            "infoTip": "Integrate with other IT systems and tools such as network monitoring, cybersecurity, and asset discovery tools for seamless data exchange and automation.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Mobile Access",
            "infoTip": "Provide access to IT management functionalities via mobile devices to enable remote monitoring and administration.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Auditing and Compliance Monitoring",
            "infoTip": "Conduct audits, assessments, and compliance checks to ensure adherence to internal policies, industry regulations, and security standards.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "User Access and Identity Management",
            "infoTip": "Manage user access rights, permissions, and authentication mechanisms to safeguard sensitive information and prevent unauthorized access.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        }      
    ],
  },
  {
    mainFeatureName: "PRODUCT MANAGEMENT AI",    
    features: [
      {
          "feature_name": "Product Information Management (PIM)",
          "infoTip": "Centralized database to store and manage detailed product information including specifications, images, pricing, and inventory levels.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Inventory Management",
          "infoTip": "Track and manage inventory levels in real-time, including stock levels, reorder points, and inventory movements.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Order Management",
          "infoTip": "Handle order processing, order tracking, and order fulfillment efficiently from receipt to delivery.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Supply Chain Management",
          "infoTip": "Coordinate and optimize the entire supply chain process including procurement, logistics, and distribution.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Product Lifecycle Management (PLM)",
          "infoTip": "Manage the entire lifecycle of products from ideation and design to manufacturing, distribution, and end-of-life.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Vendor Management",
          "infoTip": "Maintain vendor information, manage vendor relationships, and track vendor performance.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Quality Management",
          "infoTip": "Implement quality control measures to ensure product quality meets defined standards and regulations.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Cost Management",
          "infoTip": "Monitor and control product costs including material costs, manufacturing costs, and overhead expenses.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Forecasting and Demand Planning",
          "infoTip": "Use historical data and market trends to forecast demand and plan production and inventory levels accordingly.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Manufacturing Resource Planning (MRP)",
          "infoTip": "Plan and schedule production activities, manage resources, and optimize manufacturing processes.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Bill of Materials (BOM) Management",
          "infoTip": "Create and manage BOMs for products, track changes, and ensure accuracy in production.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Product Configurator",
          "infoTip": "Allow users to configure products with customizable features, options, and variants.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },      
      {
          "feature_name": "Compliance and Regulatory Management",
          "infoTip": "Ensure compliance with industry regulations, standards, and certifications throughout the product lifecycle.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Reporting and Analytics",
          "infoTip": "Generate comprehensive reports and analytics on various aspects of product management such as sales performance, inventory turnover, and profitability.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Collaboration Tools",
          "infoTip": "Facilitate collaboration and communication among cross-functional teams involved in product management including product development, marketing, sales, and customer support.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Workflow Automation",
          "infoTip": "Automate repetitive tasks and workflows to improve efficiency and reduce manual errors.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Integration with External Systems",
          "infoTip": "Seamlessly integrate with other enterprise systems such as accounting software, CRM systems, and e-commerce platforms for data synchronization and process automation.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Customer Relationship Management (CRM)",
          "infoTip": "Manage customer information, interactions, and feedback related to products and orders.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Mobile Access",
          "infoTip": "Provide mobile access to key features and functionalities of the ERP system for on-the-go access and productivity.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Scalability and Customization",
          "infoTip": "Scale the ERP system to accommodate growing business needs and customize functionalities to align with specific business requirements.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }    
    ]
  },
  {
    mainFeatureName: "JOB PORTAL AI",    
    features: [
        {
          "feature_name": "Job Search",
          "infoTip": "Allow users to search for job listings based on various criteria such as location, industry, job title, experience level, salary range, etc.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
        },
        {
            "feature_name": "Resume Parsing",
            "infoTip": "Capability to parse and extract relevant information from user-uploaded resumes to facilitate job matching.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Personalized Job Recommendations",
            "infoTip": "Provide personalized job recommendations based on user preferences, search history, and profile information.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Application Tracking",
            "infoTip": "Enable users to track the status of their job applications and receive notifications for updates or new opportunities.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Skill Matching",
            "infoTip": "Match job requirements with user skills and qualifications to suggest relevant job openings.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Company Profiles",
            "infoTip": "Provide detailed information about companies including company overview, culture, benefits, and employee reviews to help users make informed decisions.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Resume Building Assistance",
            "infoTip": "Offer tools and tips to help users create or optimize their resumes for better visibility to employers.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Interview Preparation",
            "infoTip": "Provide resources, guides, and mock interview sessions to help users prepare for job interviews.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Networking Opportunities",
            "infoTip": "Facilitate connections with professionals, recruiters, and industry peers through networking features and events.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
            "feature_name": "Salary Insights",
            "infoTip": "Provide insights into salary trends and ranges for different job roles and industries to assist users in negotiating salaries.",
            "isAssignStandard": "Yes",
            "isAssignProfessional": "No",
            "isAssignPreminum": "No",
            "isAssignElite": "Yes"
        },
        {
          "feature_name": "Remote Job Search",
          "infoTip": "Filter and display remote job opportunities for users seeking remote work options.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Skill Development Resources",
          "infoTip": "Offer access to online courses, tutorials, and resources to help users develop new skills or enhance existing ones.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Job Alerts",
          "infoTip": "Allow users to set up job alerts based on their preferences and receive notifications for new job listings matching their criteria.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Diversity and Inclusion Filters",
          "infoTip": "Enable users to filter job listings based on diversity and inclusion criteria such as gender diversity, disability-friendly workplaces, etc.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Applicant Screening",
          "infoTip": "Use AI algorithms to screen and shortlist job applications based on predefined criteria to streamline the recruitment process for employers.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Candidate Ranking",
          "infoTip": "Rank job applicants based on their suitability and match with job requirements to help employers identify top candidates.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Feedback Mechanism",
          "infoTip": "Collect feedback from users and employers to continuously improve the platform's features and user experience.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Multilingual Support",
          "infoTip": "Support multiple languages to cater to users from diverse linguistic backgrounds.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Data Privacy and Security",
          "infoTip": "Implement robust data privacy measures to protect user information and ensure compliance with data protection regulations.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Integration with Social Platforms",
          "infoTip": "Allow users to import profile information from social media platforms and share job listings with their networks.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }
    ]
  },
  {
    mainFeatureName: "CHATBOT AI",    
    features: [
      {
          "feature_name": "Natural Language Understanding (NLU)",
          "infoTip": "Ability to comprehend and interpret human language input.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Natural Language Generation (NLG)",
          "infoTip": "Capability to generate human-like responses in natural language.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Context Awareness",
          "infoTip": "Understanding and retaining context from previous interactions to maintain coherence in conversations.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Multi-turn Dialogue Management",
          "infoTip": "Handling multi-step conversations by managing dialogue flow effectively.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Personalization",
          "infoTip": "Tailoring responses based on user preferences, history, and demographics.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Intent Recognition",
          "infoTip": "Identifying the user's intention or purpose behind the input.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Entity Extraction",
          "infoTip": "Extracting relevant entities or information from user queries.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Sentiment Analysis",
          "infoTip": "Analyzing the sentiment of user input to provide appropriate responses.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Integration with External Systems",
          "infoTip": "Ability to integrate with external databases, APIs, or services to fetch or update information.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Multi-channel Support",
          "infoTip": "Supporting interaction across various channels such as websites, mobile apps, social media platforms, etc.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },      
      {
          "feature_name": "Continuous Learning",
          "infoTip": "Capability to learn from user interactions and improve over time through machine learning algorithms.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Error Handling",
          "infoTip": "Providing meaningful responses in case of misunderstandings or errors in user input.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Security and Privacy",
          "infoTip": "Ensuring data privacy and implementing security measures to protect sensitive information.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Multilingual Support",
          "infoTip": "Supporting conversations in multiple languages to cater to a diverse user base.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Analytics and Reporting",
          "infoTip": "Tracking usage statistics, conversation logs, and other metrics for analysis and optimization.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Customization and Extensibility",
          "infoTip": "Allowing developers to customize or extend the chatbot's capabilities through plugins, APIs, or scripting.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Voice Interaction",
          "infoTip": "Supporting voice-based input and output for hands-free interaction.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Human Handoff",
          "infoTip": "Ability to seamlessly transfer the conversation to a human agent when necessary for complex queries or personalized assistance.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Emotion Recognition",
          "infoTip": "Recognizing user emotions from text or voice inputs to adapt responses accordingly.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Performance Optimization",
          "infoTip": "Optimizing response time and resource utilization for efficient operation at scale.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }    
  ]
  },  
  {
    mainFeatureName: "FINANCE ERP",  
    features: [
      {
          "feature_name": "Accounting Module",
          "infoTip": "Comprehensive accounting functionalities including general ledger, accounts payable, accounts receivable, fixed assets, and bank reconciliation.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Financial Reporting",
          "infoTip": "Generate customizable financial reports such as balance sheets, income statements, cash flow statements, and trial balances.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Budgeting and Forecasting",
          "infoTip": "Create, manage, and track budgets, and perform forecasting analysis to plan for future financial performance.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Expense Management",
          "infoTip": "Streamline expense tracking, approval workflows, and reimbursement processes for employee expenses.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Financial Consolidation",
          "infoTip": "Consolidate financial data from multiple entities or subsidiaries for reporting and analysis purposes.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Tax Management",
          "infoTip": "Automate tax calculations, filings, and compliance with tax regulations at local, regional, and national levels.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Audit Trail",
          "infoTip": "Maintain a detailed audit trail of financial transactions for compliance, transparency, and accountability purposes.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Asset Management",
          "infoTip": "Track and manage company assets including acquisition, depreciation, disposal, and maintenance.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Cash Flow Management",
          "infoTip": "Monitor cash inflows and outflows, forecast cash positions, and optimize cash management strategies.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Financial Planning and Analysis (FP&A)",
          "infoTip": "Perform financial analysis, scenario modeling, and what-if analysis to support strategic decision-making.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },      
      {
          "feature_name": "Cost Management",
          "infoTip": "Analyze and control costs across various departments, projects, and activities to improve profitability.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Invoice Management",
          "infoTip": "Automate invoice processing, approval workflows, and vendor payments to streamline accounts payable processes.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Credit Management",
          "infoTip": "Manage customer credit limits, monitor credit risk, and automate credit scoring and collections processes.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Payment Processing",
          "infoTip": "Support various payment methods including electronic payments, wire transfers, checks, and direct debits.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Compliance and Regulatory Reporting",
          "infoTip": "Ensure compliance with financial regulations and standards such as GAAP (Generally Accepted Accounting Principles) or IFRS (International Financial Reporting Standards).",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Currency Management",
          "infoTip": "Handle multi-currency transactions, currency conversions, and foreign exchange risk management.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Financial Dashboard",
          "infoTip": "Provide executives and stakeholders with real-time insights into key financial metrics, KPIs, and performance indicators.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Integration with Banking Systems",
          "infoTip": "Integrate with banking APIs for direct bank feeds, reconciliation, and transaction processing.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Security and Audit Controls",
          "infoTip": "Implement robust security measures and access controls to protect financial data and ensure data integrity.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Scalability and Customization",
          "infoTip": "Scale the ERP system to accommodate growing financial needs and customize functionalities to align with specific business requirements.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }    
  ]  
  },
  {
    mainFeatureName: "PRE-DESIGNED COURSE ERP",
    features: [
      {
          "feature_name": "Course Marketplace",
          "infoTip": "A platform where pre-designed courses are showcased for users to browse and purchase.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Categories",
          "infoTip": "Organize courses into categories and subcategories for easy navigation and discovery.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Descriptions",
          "infoTip": "Detailed descriptions of each course including objectives, topics covered, prerequisites, and instructor information.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Ratings and Reviews",
          "infoTip": "Allow users to rate and review courses to help others make informed decisions.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Preview",
          "infoTip": "Provide a preview of course content, such as a sample video lesson or module, to give users a glimpse of what to expect.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Bundles",
          "infoTip": "Offer bundled courses or package deals at discounted prices to encourage users to purchase multiple courses.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Search and Filter",
          "infoTip": "Enable users to search for courses by keywords, category, level, duration, and price range.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Instructor Profiles",
          "infoTip": "Profiles for course instructors with information about their expertise, qualifications, and teaching style.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Enrollment",
          "infoTip": "Allow users to enroll in courses with a simple registration process and secure payment gateway integration.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Progress Tracking",
          "infoTip": "Track and display user progress within each course, including completed lessons, quizzes, and assignments.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },      
      {
          "feature_name": "Certification",
          "infoTip": "Issue certificates of completion or achievement to users who successfully finish courses.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Expiry and Access Control",
          "infoTip": "Set expiration dates for course access and control access permissions based on user roles or membership levels.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Updates",
          "infoTip": "Notify users of course updates, new content releases, or changes in course materials.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Customer Support",
          "infoTip": "Provide customer support channels such as live chat, email support, or a helpdesk system to assist users with course-related inquiries.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Cross-platform Compatibility",
          "infoTip": "Ensure compatibility with various devices and platforms including desktops, laptops, tablets, and smartphones.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Multilingual Support",
          "infoTip": "Offer courses in multiple languages to cater to a global audience.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Analytics and Reporting",
          "infoTip": "Generate reports on course sales, enrollment trends, user engagement, and completion rates to track the performance of courses.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Affiliate Program",
          "infoTip": "Implement an affiliate program where partners can promote courses and earn commissions on successful referrals.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Social Sharing",
          "infoTip": "Enable users to share course information and recommendations on social media platforms to expand reach and visibility.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Integration with LMS",
          "infoTip": "Integrate with Learning Management System (LMS) platforms to seamlessly transfer course data and user progress information for further learning management and tracking.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }    

  ]
  },
  {
    mainFeatureName: "SELL COURSE AI",
    features: [
      {
          "feature_name": "Course Marketplace",
          "infoTip": "Provide a platform where instructors can list and sell their courses to potential learners.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "User Authentication",
          "infoTip": "Implement secure user authentication and authorization mechanisms for both instructors and learners.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Creation Tools",
          "infoTip": "Offer intuitive tools for instructors to create and customize their courses, including uploading videos, documents, quizzes, and assignments.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Pricing and Payment Options",
          "infoTip": "Enable instructors to set prices for their courses and offer various payment options for learners, such as one-time purchases, subscriptions, or installment plans.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Revenue Tracking and Reporting",
          "infoTip": "Track course sales, revenue, and commissions for instructors, and generate reports for financial analysis.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Promotion",
          "infoTip": "Provide tools for instructors to promote their courses through marketing campaigns, discounts, and affiliate programs.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Search and Discovery",
          "infoTip": "Implement robust search and filtering capabilities for learners to discover courses based on topics, categories, ratings, and reviews.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Recommendations Engine",
          "infoTip": "Utilize AI algorithms to recommend courses to learners based on their interests, previous purchases, and learning behavior.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Dynamic Pricing",
          "infoTip": "Use dynamic pricing strategies to adjust course prices based on factors such as demand, popularity, and learner demographics.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Upselling and Cross-selling",
          "infoTip": "Recommend related or complementary courses to learners during the checkout process to increase sales and revenue.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },      
      {
          "feature_name": "Automated Email Campaigns",
          "infoTip": "Send targeted email campaigns to promote courses, engage learners, and encourage repeat purchases.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Analytics Dashboard",
          "infoTip": "Provide instructors with insights into course performance, including enrollment rates, completion rates, learner feedback, and revenue metrics.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Learner Progress Tracking",
          "infoTip": "Allow instructors to track learner progress through course materials, quizzes, assignments, and assessments.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Community Features",
          "infoTip": "Foster a sense of community among learners and instructors through discussion forums, peer-to-peer support, and networking opportunities.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Course Bundles and Packages",
          "infoTip": "Enable instructors to create bundled packages of courses at discounted prices to encourage upsells and increase value for learners.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Customizable Course Pages",
          "infoTip": "Allow instructors to customize course pages with branding, multimedia content, course descriptions, and instructor bios.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Affiliate Marketing Integration",
          "infoTip": "Integrate with affiliate marketing platforms to allow third-party affiliates to promote courses and earn commissions on sales.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Customer Support",
          "infoTip": "Provide responsive customer support channels such as live chat, email support, and helpdesk tickets to assist learners and instructors with any issues or inquiries.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Mobile Compatibility",
          "infoTip": "Ensure compatibility with mobile devices to allow learners to access and purchase courses on-the-go through responsive design or dedicated mobile apps.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Scalability and Performance",
          "infoTip": "Design the platform to handle a large volume of users, courses, and transactions while maintaining high performance and reliability.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
    ]
  },
  {
    mainFeatureName: "HRMS AI",
    features:[
      {
          "feature_name": "Recruitment Automation",
          "infoTip": "Automate various stages of the recruitment process, including candidate sourcing, screening, scheduling interviews, and applicant tracking.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Resume Parsing",
          "infoTip": "Automatically extract relevant information from resumes and applications to populate candidate profiles and databases.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Candidate Matching",
          "infoTip": "Use AI algorithms to match candidate skills, qualifications, and preferences with job requirements for better candidate selection.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Chatbot Assistance",
          "infoTip": "Provide AI-powered chatbots to answer employee queries, assist with onboarding processes, and provide HR-related information.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Employee Self-Service",
          "infoTip": "Enable employees to access and update personal information, request time off, view pay stubs, and enroll in benefits through self-service portals.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Performance Management",
          "infoTip": "Automate performance reviews, goal setting, and feedback collection processes to streamline performance management.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Learning and Development",
          "infoTip": "Recommend training courses and learning resources based on employee skills, career goals, and performance evaluations.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Attendance and Time Tracking",
          "infoTip": "Track employee attendance, working hours, and leave requests using AI-powered time tracking systems.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Predictive Analytics",
          "infoTip": "Use AI and machine learning algorithms to analyze HR data and predict employee turnover, performance trends, and staffing needs.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Compensation Management",
          "infoTip": "Manage employee compensation, bonuses, and incentives based on performance, market rates, and budget constraints.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },      
      {
          "feature_name": "Employee Engagement",
          "infoTip": "Measure employee engagement and satisfaction through surveys, feedback mechanisms, and sentiment analysis.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Succession Planning",
          "infoTip": "Identify high-potential employees and create succession plans to develop future leaders within the organization.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Compliance Management",
          "infoTip": "Ensure compliance with labor laws, regulations, and company policies through automated compliance checks and reminders.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Diversity and Inclusion Analytics",
          "infoTip": "Track diversity metrics, monitor diversity initiatives, and identify areas for improvement in diversity and inclusion efforts.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Remote Work Support",
          "infoTip": "Provide tools and resources to support remote work arrangements, including virtual collaboration tools, communication platforms, and remote access solutions.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Onboarding Automation",
          "infoTip": "Automate onboarding processes, including paperwork, training modules, and orientation sessions, to streamline the new hire experience.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Employee Wellness Programs",
          "infoTip": "Offer wellness initiatives and resources to support employee health, mental well-being, and work-life balance.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "HR Data Security",
          "infoTip": "Implement security measures to protect sensitive HR data and ensure compliance with data protection regulations.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Talent Pipelining",
          "infoTip": "Build and maintain talent pipelines for future hiring needs by proactively sourcing and nurturing candidate relationships.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Continuous Improvement",
          "infoTip": "Gather feedback from employees and HR stakeholders to continuously improve HR processes, systems, and user experience.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }    
  ]
  },
  {
    mainFeatureName: "LEGAL DOCUMENTS ERP",
    features: [
      {
          "feature_name": "Document Management",
          "infoTip": "Store, organize, and manage legal documents, contracts, agreements, and other relevant files in a centralized repository.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Version Control",
          "infoTip": "Maintain a history of document versions, track changes, and manage document revisions to ensure accuracy and compliance.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Document Templates",
          "infoTip": "Provide pre-defined templates for legal documents such as contracts, agreements, and letters to streamline document creation.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Electronic Signature Integration",
          "infoTip": "Integrate with electronic signature solutions to enable digital signing of documents for enhanced efficiency and security.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Document Collaboration",
          "infoTip": "Enable collaboration among legal teams, clients, and stakeholders for reviewing, editing, and commenting on documents.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Document Security",
          "infoTip": "Implement robust security measures such as access controls, encryption, and user permissions to protect sensitive legal documents.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Compliance Management",
          "infoTip": "Monitor and ensure compliance with legal regulations, industry standards, and contractual obligations through automated alerts and reminders.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Workflow Automation",
          "infoTip": "Automate document approval workflows, routing, and notifications to streamline document review and approval processes.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Legal Research Tools",
          "infoTip": "Provide access to legal research databases, case law repositories, and regulatory resources to support legal research and analysis.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Contract Lifecycle Management (CLM)",
          "infoTip": "Manage the entire contract lifecycle from drafting and negotiation to execution, renewal, and termination.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },      
      {
          "feature_name": "Task Management",
          "infoTip": "Assign tasks, deadlines, and responsibilities related to legal document management and track task completion status.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Reporting and Analytics",
          "infoTip": "Generate reports and analytics on document status, usage, and performance metrics to gain insights and optimize processes.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Integration with Legal Databases",
          "infoTip": "Integrate with external legal databases and research platforms to access comprehensive legal information and resources.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Client Portal",
          "infoTip": "Offer a secure portal for clients to access their legal documents, track case progress, and communicate with their legal representatives.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Document Archiving and Retention",
          "infoTip": "Archive outdated or inactive legal documents while ensuring compliance with retention policies and legal requirements.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Audit Trail",
          "infoTip": "Maintain a detailed audit trail of document activities, including edits, access, and changes, to ensure transparency and accountability.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Document Redlining and Comparison",
          "infoTip": "Facilitate redlining and comparison of document versions to highlight changes and revisions made during the review process.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Legal Document Templates",
          "infoTip": "Provide a library of customizable legal document templates for various purposes such as contracts, agreements, and letters.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Mobile Access",
          "infoTip": "Enable access to legal documents and related workflows on mobile devices to support remote work and on-the-go access.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      },
      {
          "feature_name": "Training and Support",
          "infoTip": "Offer training resources, user guides, and technical support to ensure effective use of the legal documents ERP system by legal professionals and staff.",
          "isAssignStandard": "Yes",
          "isAssignProfessional": "No",
          "isAssignPreminum": "No",
          "isAssignElite": "Yes"
      }    
  ]
  },
];
