import React from "react";
import FormPage from "./FormPage";
import "./requestdemo.css";
import WhyUse from "./WhyUse";
import MarketingCampaings from "./MarketingCampaings";
import Leading from "./Leading";
import axios from "axios";
import { useQuery } from "react-query";
import { SITETITLE } from "../../constants";
import InfoBlock from "../../components/core/info-block/InfoBlock";
import { useLocation } from "react-router-dom"; // Import the useLocation hook
import Loading from "../../components/global/Loading";
import { Helmet } from "react-helmet";

const RequestDemo = () => {
  const location = useLocation(); // Get the current location object
  const queryParams = new URLSearchParams(location.search); // Create a URLSearchParams object from the query string
  const featureName = queryParams.get("featureName"); // Get the value of the "featureName" query parameter

  const getFeaturedListData = async () => {
    const response = await axios.get(
      window.apiBaseUrl + `request-demo/sub-features?featureName=${featureName}`
    );
    return response.data.data;
  };

  // header banner
  const getHeaderData = async () => {
    const response = await axios.get(
      window.apiBaseUrl + `request-demo?featureName=${featureName}`
    );
    return response.data.data;
  };
  // // Marketing Campign
  const getMarketingCampaignData = async () => {
    const response = await axios.get(
      window.apiBaseUrl +
        `request-demo/marketing-campaign?featureName=${featureName}`
    );

    return response.data.data;
  };

  // //  Leading Global
  const getLeadingGlobalData = async () => {
    const response = await axios.get(
      window.apiBaseUrl + `home/testimonials?featurePage=cosmos-iq-business`
      // `request-demo/leading-global-brands?featureName=${featureName}`
    );
    return response.data.data;
  };

  // // why Use
  const getWhyUseCosmosData = async () => {
    const response = await axios.get(
      window.apiBaseUrl +
        `request-demo/why-use-cosmos?featureName=${featureName}`
    );
    return response.data.data;
  };

  const { data: headerData, isLoading: headerLoading } = useQuery(
    "getHeaderData",
    getHeaderData,
    { cacheTime: 0 }
  );

  const { data: featuredListData, isLoading: featureLoading } = useQuery(
    "enterpriseFeaturedList",
    getFeaturedListData,
    { cacheTime: 0 }
  );

  const { data: marketingCampaignData, isLoading: marketingLoading } = useQuery(
    "marketingCampaign",
    getMarketingCampaignData,
    { cacheTime: 0 }
  );

  const { data: leadingData, isLoading: leadingLoading } = useQuery(
    "getLeadingData",
    getLeadingGlobalData,
    { cacheTime: 0 }
  );

  const { data: whyUseCosmos, isLoading: whyUseCosmosLoading } = useQuery(
    "getWhyUseCosmosData",
    getWhyUseCosmosData,
    { cacheTime: 0 }
  );

  // if (error) return <div>An error occurred: {error.message}</div>;
  if (
    headerLoading ||
    marketingLoading ||
    leadingLoading ||
    whyUseCosmosLoading ||
    featureLoading
  )
    return <Loading />;

  return (
    <>
      <Helmet>
        <title>{headerData ? headerData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={headerData ? headerData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={headerData ? headerData.metaKeywords : SITETITLE}
        />
      </Helmet>
      {/* HeaderBanner */}
      {headerData ? (
        <>
          <FormPage data={headerData} />
        </>
      ) : (
        ""
      )}

      {whyUseCosmos ? (
        <>
          <WhyUse HeaderData={headerData} CardData={whyUseCosmos.list} />
        </>
      ) : (
        ""
      )}

      {featuredListData && featuredListData.list ? (
        <>
          {featuredListData.list.map((list, index) => (
            <InfoBlock
              key={index + 1}
              imgUrl={list.featuredImagePath}
              subtitle={list.heading}
              title={list.subHeading}
              description={list.description}
              buttonsOne={list.buttonsOne}
              buttonsLinkOne={list.buttonsLinkOne}
              buttonsTwo={list.buttonsTwo}
              buttonsLinkTwo={list.buttonsLinkTwo}
              featureName={list.featuredName}
              number={index + 1}
            />
          ))}
        </>
      ) : (
        ""
      )}

      {marketingCampaignData ? (
        <>
          <MarketingCampaings
            HeaderData={headerData}
            data={marketingCampaignData.list}
          />
        </>
      ) : (
        ""
      )}

      {leadingData ? (
        <>
          <Leading
            HeaderData={leadingData.staticData}
            LeadingData={leadingData.list}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default RequestDemo;
