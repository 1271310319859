import React from "react";
import KeyFeatures from "../../components/core/key-features/KeyFeatures";
// import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import InfoBlock from "../../components/core/info-block/InfoBlock";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import Loading from "../../components/global/Loading";
import MyCarousel from "../home/Carousel/Carousel";

const getInfoSectionData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "for-enterprise/best-edtech-software"
  );
  return response.data.data;
};

const getFeaturedListData = async () => {
  const response = await axios.get(
    window.apiBaseUrl +
      "for-enterprise/featured-list?pageName=cosmos-iq-business"
  );
  return response.data.data;
};

const getWhyChooseUsData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "for-enterprise/why-choose-us"
  );
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "for-enterprise/meta-details"
  );
  return response.data.data;
};

const ForEnterprise = () => {
  const { data: metaData } = useQuery("metaData", getMetaData);
  const { data: infoBlockData, isLoading: infoSectionLoading } = useQuery(
    "infoSection",
    getInfoSectionData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );
  const { data: featuredListData, isLoading: featuredListLoading } = useQuery(
    "enterpriseFeaturedList",
    getFeaturedListData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );
  const { data: whyChooseUs, isLoading: whyChooseUsDataLoading } = useQuery(
    "getWhyChooseUsData",
    getWhyChooseUsData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  if (infoSectionLoading || featuredListLoading || whyChooseUsDataLoading)
    return <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>

      {featuredListData && featuredListData.length > 0 ? (
        <MyCarousel items={featuredListData} />
      ) : (
        ""
      )}

      {infoBlockData ? (
        <InfoBlock
          imgUrl={infoBlockData.digitalCampusImage}
          subtitle={infoBlockData.digitalCampusSubHeading}
          title={infoBlockData.digitalCampusHeading}
          description={infoBlockData.digitalCampusDescription}
          buttonsOne={infoBlockData.buttonsOne}
          buttonsLinkOne={infoBlockData.buttonsLinkOne}
          buttonsTwo={infoBlockData.buttonsTwo}
          buttonsLinkTwo={infoBlockData.buttonsLinkTwo}
          featureName={infoBlockData.featuredName}
          number="1"
        />
      ) : (
        ""
      )}

      {featuredListData && featuredListData.length > 0 ? (
        <>
          {featuredListData.map((list, index) => (
            <InfoBlock
              key={index + 1}
              imgUrl={list.featuredImagePath}
              subtitle={list.heading}
              title={list.subHeading}
              description={list.description}
              buttonsOne={list.buttonsOne}
              buttonsLinkOne={list.buttonsLinkOne}
              buttonsTwo={list.buttonsTwo}
              buttonsLinkTwo={list.buttonsLinkTwo}
              featureName={
                list.featureNameSlugUrl
              } /*add featuredName ={list.featuredName} */
              number={index + 1}
            />
          ))}
        </>
      ) : (
        ""
      )}

      {whyChooseUs ? (
        <KeyFeatures
          pageTitle={"Why Choose Us"}
          // pageSubTitle={"Why Join Us"}
          content={whyChooseUs}
          customClass="col-md-3"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ForEnterprise;
