import React from "react";
import { Link, useNavigate } from "react-router-dom";

const NoRecordFound = (props) => {

  const navigate = useNavigate();

  const handleClick = (arg) => {
    navigate(arg);
    window.location.reload();
  }

  return (
    <div className="mt-5 py-5 text-center">
      <div>
        <lord-icon
          src="https://cdn.lordicon.com/wzwygmng.json"
          trigger="loop"
          stroke="bold"
          colors="primary:#405189,secondary:#545454"
          style={{ width: "72px", height: "72px" }}
        ></lord-icon>
      </div>

      <div className="mt-4 mb-3">
        <h5>Sorry! No Result Found</h5>
      </div>
      <Link onClick={() => handleClick(props.link)} className="btn btn-primary">
        {props.buttonName}
      </Link>
    </div>
  );
};

export default NoRecordFound;
