import React from "react";

const BlogHeaderBanner = () => {
  return (
    <div
      className="section page-banner-section bg-color-1 blog-banner"
      style={{
        backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/bg_blog_banner.webp"
          )`,
      }}
    >
      <div className="container">
        <div className="page-banner-content">
          <h1 className="title">Latest Blogs</h1>
        </div>
      </div>
    </div>
  );
};

export default BlogHeaderBanner;
