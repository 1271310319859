import React from "react";
import InstructorCountUpComponent from "../../components/page-wise-components/instructor/InstructorCountUpComponent";
import axios from "axios";
import { useQuery } from "react-query";
import WhyChooseUsComponent from "../../components/page-wise-components/instructor/WhyChooseUsComponent";
import ForTeachingComponent from "../../components/page-wise-components/instructor/ForTeachingComponent";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import InfoBlock from "../../components/core/info-block/InfoBlock";
import InstructorMobileAppSection from "../../components/page-wise-components/home/MobileAppSection/InstructorMobileAppSection";
import Loading from "../../components/global/Loading";
import MyCarousel from "../home/Carousel/Carousel";

const getBanner = async () => {
  const response = await axios.get(window.apiBaseUrl + "for-instructor/banner");
  return response.data.data;
};

const getCounterData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "for-instructor/counter"
  );
  return response.data.data;
};

const getFeaturedListData = async () => {
  const response = await axios.get(
    window.apiBaseUrl +
      "for-enterprise/featured-list?pageName=teach-on-cosmos-iq"
  );
  return response.data.data;
};

const getDownloadAppData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "for-instructor/download-apps"
  );
  return response.data.data;
};

const getWhyChooseUsData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "for-instructor/best-lms-software"
  );
  return response.data.data;
};

const getForTeachingData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "for-instructor/best-edtech-teaching-software"
  );
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "for-instructor/meta-details"
  );
  return response.data.data;
};

const Instructor = () => {
  const {
    // data: headerBannerData,
    // error,
    isLoading: instructorBannerLoading,
  } = useQuery("instructorBannerData", getBanner, {
    cacheTime: 0, // Disable caching for this query
  });
  const { data: metaData } = useQuery("metaData", getMetaData);
  const { data: counterData, isLoading: instructorCounterLoading } = useQuery(
    "counterData",
    getCounterData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  const { data: featuredListData, isLoading: instructorFeatureLoading } =
    useQuery("instructorFeaturedList", getFeaturedListData, {
      cacheTime: 0, // Disable caching for this query
    });

  const { data: whyChooseUsData, isLoading: instructorWhyChooseUsLoading } =
    useQuery("whyChooseUsData", getWhyChooseUsData, {
      cacheTime: 0, // Disable caching for this query
    });

  const { data: downloadAppData, isLoading: downloadAppLoading } = useQuery(
    "facultyDownloadApp",
    getDownloadAppData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  const { data: forTeachingData, isLoading: forTeachingLoading } = useQuery(
    "forTeaching",
    getForTeachingData,
    {
      cacheTime: 0, // Disable caching for this query
    }
  );

  if (
    instructorBannerLoading ||
    instructorCounterLoading ||
    instructorFeatureLoading ||
    instructorWhyChooseUsLoading ||
    forTeachingLoading
  )
    return <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>

      {featuredListData ? <MyCarousel items={featuredListData} /> : ""}

      {counterData ? <InstructorCountUpComponent data={counterData} /> : ""}

      {featuredListData ? (
        <>
          {featuredListData.map((list, index) => (
            <InfoBlock
              key={index + 1}
              imgUrl={list.featuredImagePath}
              subtitle={list.heading}
              title={list.subHeading}
              description={list.description}
              buttonsOne={list.buttonsOne}
              buttonsLinkOne={list.buttonsLinkOne}
              buttonsTwo={list.buttonsTwo}
              buttonsLinkTwo={list.buttonsLinkTwo}
              featureName={list.featuredName}
              number={index + 1}
            />
          ))}
        </>
      ) : (
        ""
      )}
      {whyChooseUsData ? <WhyChooseUsComponent data={whyChooseUsData} /> : ""}

      {downloadAppData ? (
        <InstructorMobileAppSection data={downloadAppData} />
      ) : (
        ""
      )}

      {forTeachingData ? <ForTeachingComponent data={forTeachingData} /> : ""}
    </>
  );
};

export default Instructor;
