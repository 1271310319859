import React from "react";
import { Link } from "react-router-dom";

const JobProfiles = ({ data }) => {
  return (
    <>
      <section className="terms-qutaion career-openings pt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="featured-head">Featured Jobs</h2>
            </div>
          </div>
          
          {data?(
          <div className="row">
            {data.map((job, index) => (
              <div key={index} className="col-md-6">
                <div className="job-listing">
                  <h4>{job.jobTitle}</h4>
                  <Link
                    to={`/jobs/${job.jobTitleSlugUrl}`}
                    className="button lets-start ml-0"
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
            ))}
          </div>
          ):''}

        </div>
      </section>
    </>
  );
};

export default JobProfiles;
