import React from "react";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";

const getNoticeList = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "notice-board/notice-list"
  );
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "notice-board/meta-details"
  );
  return response.data.data;
};

const NoticeBoard = () => {
  const {
    data: meta,
    // error,
    isLoading,
  } = useQuery("metaData", getMetaData, { cacheTime: 0 });
  const { data: notice } = useQuery("noticeData", getNoticeList, {
    cacheTime: 0,
  });
  const metaData = meta;
  const noticeData = notice;

  if (isLoading) return <div>Fetching posts...</div>;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>

      <HeaderBanner
        imgUrl="https://www.matriyeacademy.com/website-assets/images/Notice_board_bg_banner.png"
        subtitle={""}
        title="Notice Board"
        description="Stay tuned for any upcoming events and announcements."
        buttonsOne={""}
        buttonsLinkOne={""}
        buttonsTwo={""}
        buttonsLinkTwo={""}
      />

      <section className="course-details notice-board pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 course-list">
              <div className="course-detail">
                <form method="get" siq_id="autopick_6366">
                  <div className="row">
                    <div className="col-md-9">
                      <h3>Notice Board</h3>
                    </div>
                    <div className="col-md-3">
                      <select
                        className="form-control"
                        id="sortby"
                        name="sortby"
                      >
                        <option defaultValue="">Sort By</option>
                        <option defaultValue="startDate">Start Date </option>
                        <option defaultValue="endDate">End Date</option>
                      </select>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="time-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(noticeData) ? (
                        <>
                          {noticeData.map((notices, index) => (
                            <tr key={index + 1}>
                              <td>{notices.title}</td>
                              <td>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: notices.description,
                                  }}
                                ></div>
                              </td>
                              <td>{notices.startDate}</td>
                              <td>{notices.endDate}</td>
                              <td>
                                {notices.fileType === "link" ? (
                                  <a
                                    href={notices.noticeLink}
                                    className="pdf-view"
                                  >
                                    <i
                                      className="fa fa-file-pdf"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    View Link
                                  </a>
                                ) : (
                                  <a
                                    href={notices.noticeFile}
                                    className="pdf-view"
                                  >
                                    <i
                                      className="fa fa-file-pdf"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    View File
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan="5">No record found</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="page-pagination">
                  <ul className="pagination justify-content-center"></ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoticeBoard;
