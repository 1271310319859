const FAQData = [
    {
        question:"What are the available COSMOS IQ Business plans?",
        answer:"  COSMOS IQ offers three tiers of Business plans: STANDARD, PROFFESSIONAL, PREMIUM, and ELITE. Each plan is tailored to meet the specific needs of businesses of different sizes and requirements."
    },
    {
        question:"What is the prize for the Standard plan?",
        answer:"The Basic plan pricing is Rs 4999."
    },
    {
        question:"What is the prize for Pro plan?",
        answer:"The Pro plan pricing is Rs 14999."
    },
    {
        question:"What is the prize for premium plan?",
        answer:"The Enterprise plan premium plan is Rs 24999."
    },
    {
        question:"Can I customize my plan based on my business requirements?",
        answer:"Yes, COSMOS IQ offers customizable plans for businesses with specific needs. Contact our sales team to discuss your requirements and tailor a plan that fits your business perfectly."
    },
    {
        question:"Is there a free trial available for the Business plans?",
        answer:"Yes, COSMOS IQ offers a free trial for all Business plans. Sign up for a trial to experience the features and capabilities of our platform firsthand."
    },
    {
        question:"How is pricing determined for COSMOS IQ Business plans?",
        answer:"Pricing for Business plans is based on factors such as the selected plan tier, the number of users, and any additional customization or add-on features. Contact our sales team for detailed pricing information tailored to your business."
    },
    {
        question:"How can I get started with a COSMOS IQ Business plan?",
        answer:"To get started with a COSMOS IQ Business plan, visit our website and sign up for a free trial or contact our sales team to discuss your requirements and get personalized assistance with selecting the right plan for your business."
    },
    {
        question:"What Payment methods does COSMOS IQ accept?",
        answer:"COSMOS IQ accepts payments via various platforms including credit/debit cards, PayPal, bank transfers, and electronic fund transfers (EFT)."
    },
    {
        question:"How can I updated my payemnt information?",
        answer:"You can update your payment information by logging into your COSMOS IQ account and navigating to the billing or payment settings. From there, you can add or update your payment method details."
    },
    {
        question:"Can I receive invoices for my payemnts?",
        answer:"Yes, COSMOS IQ provides invoices for all payments made. You can access and download your invoices from your account dashboard or request them from our billing support team."
    },
    {
        question:"How do I resolve payemnt-related issues or inquuires?",
        answer:"If you encounter any issues or have inquiries regarding payments, you can contact our billing support team for assistance. They will be happy to help resolve any concerns or answer any questions you may have."
    },
    {
        question:"Is my payment informaation secure?",
        answer:"Yes, COSMOS IQ takes the security of your payment information seriously. We use industry-standard encryption and security protocols to ensure that your payment details are protected at all times."
    },
    {
        question:"Where I can find more information about the payment process?",
        answer:"For more information about the payment process or if you have specific questions not addressed here, please refer to our payment FAQs on our website or contact our billing support team directly."
    },
]

export default FAQData;