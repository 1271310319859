import React, { useContext } from "react";
// import Map from "../components/page-wise-components/contact-us/Map.jsx";
// import ContactForm from "../components/page-wise-components/contact-us/ContactForm.jsx";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../constants.js";
import Loading from "../components/global/Loading.jsx";
import ContactUsHeader from "./contact-us/ContactUsHeader.jsx";
import ContactUsCard from "./contact-us/ContactUsCard.jsx";

// import contactData from "./contact-us/contactCardData.json";

import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ContactContext from "../components/global/ContactContext.js";

const getMetaData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "contact-us/meta-details"
  );
  return response.data.data;
};

const ContactUs = () => {
  const { setContactData } = useContext(ContactContext);

  const [showModal, setShowModal] = React.useState(false);
  const [currentCard, setCurrentCard] = React.useState("");
  const [currentCardOptions, setCurrentCardOptions] = React.useState("");

  const handleButtonClick = (item) => {
    setCurrentCard(item);
    setCurrentCardOptions(item.options);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const {
    data: metaData,
    // error,
    isLoading,
  } = useQuery("noticeData", getMetaData, { cacheTime: 0 });

  if (isLoading) <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>
      {/* <Map />*/}

      <>
        <ContactUsHeader
          title="What can we help you with?"
          description="Choose a category below to get started."
        />
        <Modal
          size="md"
          centered
          show={showModal}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          className=""
        >
          <Modal.Header closeButton>
            <Modal.Title>{currentCard.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="become-section-outer pt-3 pb-1">
              <div className="row">
                {Array.isArray(currentCardOptions) &&
                  currentCardOptions.map((option, index) => (
                    <Link
                      className="col-md-6"
                      to="/reach-out"
                      key={index + 1}
                      style={{ cursor: "auto" }}
                      onClick={() =>
                        setContactData({
                          menu: currentCard.title,
                          subMenu: option.name,
                        })
                      }
                    >
                      <div
                        className="contactModal-become-inner c1"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="become-inner-inner">
                          <div className="become-content pt-1">
                            <h4 className="text-white fw-normal fs-6">
                              {option.name}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <ContactUsCard onButtonClick={handleButtonClick} />
      </>
    </>
  );
};
export default ContactUs;
