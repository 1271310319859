import React from "react";
import { Col, Row } from "react-bootstrap";
import img from "./fan-avatar.png";
import "./ContactUs.css";

const ContactUsHeader = (props) => {
  return (
    <div
      className="contactUsHeaderContainer"
      style={{
        // height: "250px",
        backgroundColor: "",
        marginTop: "90px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Row
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col md={8} className="d-flex justify-content-center ps-lg-5 ">
          <div className="content">
            <h1 className="text-dark ">{props.title}</h1>
            <p className="py-2 text-dark ">{props.description}</p>
          </div>
        </Col>
        <Col md={4} className="d-flex justify-content-center">
          <div
            className="image-container"
            style={{
              width: "250px",
              height: "250px",
              position: "relative",
              zIndex: "20",
              bottom: "-37px",
            }}
          >
            <img src={img} alt="" style={{ width: "100%", height: "100%" }} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUsHeader;
