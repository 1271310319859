import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SITETITLE } from "../constants";
import Pagination from "./pagination";
import NoRecordFound from "../components/core/no-record-found/NoRecordFound";
import Loading from "../components/global/Loading";

const DepartmentList = () => {
  const { departmentNameSlugUrl } = useParams();
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentRecords, setDepartmentRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const formData = new FormData();
  formData.append("departmentNameSlugUrl", departmentNameSlugUrl);

  useEffect(() => {
    getSubjectDetailsData();
  }, []);

  const getSubjectDetailsData = async () => {
    setIsLoading(true);
    await axios
      .post(window.apiBaseUrl + "home/top-department-courses", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status === 200) {
          setDepartmentList(res.data.data.list);
          setDepartmentRecords(res.data.data.totalRecords);
        } else {
          toast.warn(res.data.message, {
            autoClose: 5000,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message, {
        //   autoClose: 5000,
        //   theme: "colored",
        // });
      });
    setIsLoading(false);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const departmentData = departmentList
    ? departmentList.slice(startIdx, endIdx)
    : [];

  const totalPages = Math.ceil(departmentRecords / itemsPerPage);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{SITETITLE} :: Top Department Courses</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <div
        className="section page-banner-section bg-color-1 top-department-courses-banner"
        style={{
          backgroundImage: `url(
            "https://www.matriyeacademy.com/website-assets/images/top-department-courses-banner.webp"
          )`,
        }}
      >
        <div className="container">
          <div className="page-banner-content">
            <h1 className="title">Top department courses</h1>
          </div>
        </div>
      </div>
      <div className="section section-padding">
        <div className="container">
          <div className="course-list-wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="grid">
                    {departmentData.length > 0 ? (
                      <div className="row">
                        {departmentData.map((department, index) => (
                          <div key={index} className="col-lg-3 col-sm-6">
                            <div className="single-course">
                              <div className="courses-image">
                                <div className="course-tag">
                                  <p>{department.categoryName}</p>
                                </div>
                                <Link
                                  to={`/filter?q=${department.courseName
                                    .toLowerCase()
                                    .replace(/\s+/g, "+")}`}
                                >
                                  <img
                                    src={department.courseImage}
                                    alt={department.courseId}
                                    loading="lazy"
                                  />
                                </Link>
                              </div>
                              <div className="courses-content">
                                <p className="course_department">
                                  {department.departmentName}
                                </p>
                                <h3 className="title">
                                  <Link
                                    to={`/filter?q=${department.courseName
                                      .toLowerCase()
                                      .replace(/\s+/g, "+")}`}
                                  >
                                    {department.courseName}
                                  </Link>
                                </h3>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <NoRecordFound
                        link="/home"
                        buttonName="Explore our Department"
                      />
                    )}
                  </div>
                </div>

                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DepartmentList;
