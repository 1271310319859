import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

function OffCanvas({ btn, course }) {

  // State variables

  const [showMain, setShowMain] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showSelectedItems, setShowSelectedItems] = useState(false);

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTopicItems, setSelectedTopicItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  // New state for history
  const [history, setHistory] = useState([]);

  // Close main OffCanvas and reset states
  const handleCloseMain = () => {
    setShowMain(false);
    setSelectedTopic(null);
    setSelectedTopicItems([]);
    setHistory([]); // Clear history when closing the main offcanvas
  };

  // Show main OffCanvas and clear history
  const handleShowMain = () => {
    setShowMain(true);
    setHistory([]); // Clear history when showing the main offcanvas
  };

  // Handle topic click, show details OffCanvas, and update history
  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    const items = course[topic];
    setSelectedTopicItems(Array.isArray(items) ? items : Object.keys(items));
    setShowMain(false);
    setShowDetails(true);
    setHistory([...history, "main"]); // Push "main" to history
  };

  // Close details OffCanvas and update history
  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedItems([]);
    setHistory(history.slice(0, -1)); // Remove the last item from history
  };

  // Handle selected item click, show selected items OffCanvas, and update history
  const handleSelectedItemClick = (item) => {
    setSelectedItems(course[selectedTopic][item]);
    setShowDetails(false);
    setShowSelectedItems(true);
    setHistory([...history, "details"]); // Push "details" to history
  };

  // Close selected items OffCanvas and update history
  const handleCloseSelectedItems = () => {
    setShowSelectedItems(false);
    setSelectedItems([]);
    setHistory(history.slice(0, -1)); // Remove the last item from history
  };

  // Handle back button click to navigate back in history
  const handleBackButton = () => {
    const lastState = history[history.length - 1];
    if (lastState === "main") {
      setShowMain(true);
      setShowDetails(false);
      setShowSelectedItems(false);
    } else if (lastState === "details") {
      setShowDetails(true);
      setShowSelectedItems(false);
    }
    setHistory(history.slice(0, -1)); // Remove the last item from history
  };

  return (
    <>
      {/* Button to show main OffCanvas */}
      <ul>
        <li onClick={handleShowMain}>
          {btn}
          <i className="ri-arrow-right-s-line text-secondary"></i>
        </li>
      </ul>

      {/* Main OffCanvas */}
      <Offcanvas show={showMain} onHide={handleCloseMain}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{"Explore Our Courses"}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            {/* Render topics in main OffCanvas */}
            {course &&
              Object.keys(course).map((item, key) => (
                <li
                  key={key}
                  className="d-flex align-items-center justify-content-between"
                  onClick={() => handleTopicClick(item)}
                >
                  {item}
                  <i className="ri-arrow-right-s-line text-secondary fs-5"></i>
                </li>
              ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Details OffCanvas */}
      <Offcanvas show={showDetails} onHide={handleCloseDetails}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{selectedTopic}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
          <li 
            style={{backgroundColor:"#d8e0fb"}}
            onClick={handleBackButton} 
            className="d-flex align-items-center gap-3 text-dark p-1 mb-2">
            <i className="ri-arrow-left-s-line text-dark fw-bold  "></i>

              Back
            </li>
            {/* Render selected topic items in Details OffCanvas */}
            {selectedTopicItems.map((item, key) => (
              <li
                key={key}
                className="d-flex align-items-center justify-content-between"
                onClick={() => handleSelectedItemClick(item)}
              >
                {item}
                <i className="ri-arrow-right-s-line text-secondary fs-5"></i>
              </li>
            ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      {/* Selected Items OffCanvas */}
      <Offcanvas show={showSelectedItems} onHide={handleCloseSelectedItems}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Popular course</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul>
            {/* Back button for Selected Items OffCanvas */}
            <li 
            style={{backgroundColor:"#d8e0fb"}}
            onClick={handleBackButton} 
            className="d-flex align-items-center gap-3 text-dark p-1 mb-2">
            <i className="ri-arrow-left-s-line text-dark fw-bold  "></i>

              Back
            </li>
            {/* Render selected items in Selected Items OffCanvas */}
            {selectedItems.map((item, key) => (
              <li className="p-2 fw-normal" key={key} >
                {item}
              </li>
            ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvas;
