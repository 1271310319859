import * as React from "react";
// import { styled } from '@mui/material/styles';
// import Button from '@mui/material/Button';
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export default function VariableWidth({ infoTip }) {
  return (
    <div>
      <Tooltip title={infoTip}>
        <InfoOutlinedIcon className="text-primary fs-6 bg-transparent" />
      </Tooltip>
    </div>
  );
}
