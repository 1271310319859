import React from "react";
import "./pagination.css";

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((number) => {
    if (
      number === 1 ||
      number === totalPages ||
      (number >= currentPage - 2 && number <= currentPage + 2)
    ) {
      return (
        <li key={number}>
          <button
            onClick={() => onPageChange(number)}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            {number}
          </button>
        </li>
      );
    } else if (number === currentPage - 3 || number === currentPage + 3) {
      return (
        <li key={number} className="page-item">
          <span className="page-link">...</span>
        </li>
      );
    }
    return null;
  });

  return (
    <nav className="page-pagination">
      <ul className="pagination justify-content-center">
        {currentPage > 1 && (
          <li className="page-item">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              className="page-link"
            >
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </button>
          </li>
        )}
        {renderPageNumbers}
        {currentPage < totalPages && (
          <li className="page-item">
            <button
              onClick={() => onPageChange(currentPage + 1)}
              className="page-link"
            >
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
