import React from 'react'

const WhyChooseUsComponent = (props) => {
  return (
    <>
    <section className="about-us-trial instructor-about">
        <div className="container">
          <div className="feature-intro space-top text-center">
            <h2 className="head-choose">{props.data.staticData}</h2>
            {props.data.pointList?(
            <div className="row">
              {props.data.pointList.map((points, index) => (
                <div key={index} className="col-lg-3 col-md-4 style-s1 d-flex">
                  <div className="feature-inner flex-fill">
                    <div className="icon-box">
                      <img
                        src={points.whyChooseUsIcon}
                        alt={points.whyChooseUsId}
                        loading="lazy"
                      />
                    </div>
                    <div className=" text-box-new">
                      <h3>{points.whyChooseUsTitle}</h3>                      
                      <div dangerouslySetInnerHTML={{ __html: points.whyChooseUsDescription }}></div>                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
            ):''}
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUsComponent
