import React from "react";
import { Modal } from "react-bootstrap";

const CourseVideoModal = ({ show, onHide, videoLink }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton style={{ paddingTop: "0" }}></Modal.Header>
      <Modal.Body>
        <iframe
          title="Video Player"
          width="100%"
          height="400"
          src={videoLink}
          frameborder="0"
          allowfullscreen="true"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default CourseVideoModal;
