import React from "react";
import KeyFeatures from "../../components/core/key-features/KeyFeatures";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import CareerInfoBlock from "../../components/page-wise-components/career/CareerInfoBlock";
import CareerQualitySection from "../../components/page-wise-components/career/CareerQualitySection";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants";
import Loading from "../../components/global/Loading";

const getBanner = async () => {
  const response = await axios.get(window.apiBaseUrl + "career/banner");
  return response.data.data;
};

const getAreYouABeliever = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "career/are-you-beliver"
  );
  return response.data.data;
};

const getAdvantagesData = async () => {
  const response = await axios.get(window.apiBaseUrl + "career/advantages");
  return response.data.data;
};

const getWhyChooseUsData = async () => {
  const response = await axios.get(window.apiBaseUrl + "career/why-join-us");
  return response.data.data;
};

const getMetaData = async () => {
  const response = await axios.get(window.apiBaseUrl + "career/meta-details");
  return response.data.data;
};

const Career = () => {
  const {
    data: headerBannerData,
    // error,
    isLoading: careerBannerLoading,
  } = useQuery("careerBannerData", getBanner, { cacheTime: 0 });
  const { data: metaData } = useQuery("metaData", getMetaData, {
    cacheTime: 0,
  });
  const { data: areYouBeliver, isLoading: careerAreYouBeliverLoading } =
    useQuery("getAreYouABeliever", getAreYouABeliever, { cacheTime: 0 });
  const { data: advantages, isLoading: careerAdvantagesLoading } = useQuery(
    "getAdvantagesData",
    getAdvantagesData,
    { cacheTime: 0 }
  );
  const { data: whyChooseUs, isLoading: careerWhyChooseUsLoading } = useQuery(
    "getWhyChooseUsData",
    getWhyChooseUsData,
    { cacheTime: 0 }
  );

  if (
    careerBannerLoading ||
    careerAreYouBeliverLoading ||
    careerAdvantagesLoading ||
    careerWhyChooseUsLoading
  )
    return <Loading />;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{metaData ? metaData.metaTitle : SITETITLE}</title>
        <meta
          name="description"
          content={metaData ? metaData.metaDescription : SITETITLE}
        />
        <meta
          name="keywords"
          content={metaData ? metaData.metaKeywords : SITETITLE}
        />
      </Helmet>
      {headerBannerData ? (
        <HeaderBanner
          imgUrl={headerBannerData.bannerImage}
          subtitle={headerBannerData.bannerHeading}
          title={headerBannerData.bannerSubHeading}
          description={headerBannerData.bannerDescription}
          buttonsOne={headerBannerData.buttonNameOne}
          buttonsLinkOne={headerBannerData.buttonLinkOne}
          buttonsTwo={headerBannerData.buttonNameTwo}
          buttonsLinkTwo={headerBannerData.buttonLinkTwo}
        />
      ) : (
        ""
      )}
      {areYouBeliver ? <CareerInfoBlock data={areYouBeliver} /> : ""}
      {advantages ? <CareerQualitySection data={advantages} /> : ""}
      {whyChooseUs ? (
        <KeyFeatures
          pageTitle={"Why Join Us"}
          // pageSubTitle={"Why Join Us"}
          content={whyChooseUs}
          customClass="col-md-4"
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Career;
