import React from 'react'

const ForTeachingComponent = (props) => {
  return (
    <>
    <section className="teaching-section">
        <div className="container">
          <div className="section-title">
            <div className="row align-items-center justify-content-md-between">
              <div className="col-md-12">
                <h2 className="title">
                  {props.data.bestEdtechMainHeading}
                </h2>
              </div>
            </div>
          </div>
          {props.data.imageList?(
          <div className="row">
            {props.data.imageList.map((list, index) => (
                <div key={index} className="col-md-3 d-flex">
                    <div className="teaching-section-inner flex-fill">
                        <h3>{list.edtechTitle}</h3>
                        <p>{list.edtechDescription}</p>
                    </div>
                </div>
            ))}
          </div>
          ):''}
        </div>
      </section>
    </>
  );
};

export default ForTeachingComponent
