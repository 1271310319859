import React from "react";
import "./ContactUs.css";
import contactData from "./contactCardData.json";

const ContactUsCard = ({ onButtonClick }) => {
  return (
    <>
      <section className="become-section pt-3">
        <div className="container" style={{ width: "80%" }}>
          <div className="become-section-outer">
            <div className="row">
              {contactData.map((item, index) => (
                <div className="col-lg-3 col-md-4" key={index}>
                  <div
                    className="contact-become-inner c1 "
                    style={{ cursor: "pointer" }}
                    onClick={() => onButtonClick(item)}
                  >
                    <div className="become-inner-inner">
                      <div className="become-image">
                        <i className={`fs-2 ${item.icon}`}></i>
                      </div>
                      <div className="become-content pt-2">
                        <h4 className="text-white fw-medium">{item.title}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsCard;
