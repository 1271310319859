import React, { useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const HomeCountUpComponent = (props) => {
  const [counterStart, setCounterStart] = useState(false);
  return (
    <>
      <ScrollTrigger onEnter={() => setCounterStart(true)}>
        <section className="counter-section-home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="counter-section-counts">
                {props.data?(
                  <div className="row justify-content-center">
                  {props.data.map((counterData, index) => (
                    <div key={index} className="col-lg-2 col-6">
                      <div className="counter-section-counts-outer">
                        <h6 className="fadeIn">
                          <span className="counter fadeInDownBig">
                            {counterStart && (
                              <CountUp
                                start={0}
                                end={parseInt(counterData.counter.match(/\d+/), 10)}
                                duration={3}
                                scrollSpyOnce
                              />
                            )}
                          </span>
                          {counterData.counter.replace(/\d+/, '')}
                        </h6>
                        <p>{counterData.counterTitle}</p>
                      </div>
                    </div>
                  ))}
                  </div>
                ):''}
                </div>
              </div>
            </div>
          </div>
        </section>
      </ScrollTrigger>
    </>
  );
};

export default HomeCountUpComponent;
