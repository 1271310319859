import React from "react";
import jsonData from "./CareerDetalisData.json";
import HeaderBanner from "../../components/core/header-banner/HeaderBanner";
import JobProfiles from "../../components/page-wise-components/career/JobProfiles";
import CareerRelatedKnowledge from "../../components/page-wise-components/career/CareerRelatedKnowledge.jsx";
import axios from "axios";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { SITETITLE } from "../../constants.js";

const getCareerDetailsData = async () => {
  const response = await axios.get(
    window.apiBaseUrl + "career/career-solutions"
  );
  return response.data.data;
};

const getJobListData = async () => {
  const response = await axios.get(window.apiBaseUrl + "career/job-list");
  return response.data.data;
};

const CareerDetails = () => {
  const careerDeailsdata = jsonData[1];

  const {
    data: jobList,
    // error,
    isLoading,
  } = useQuery("jobListData", getJobListData, { cacheTime: 0 });
  const { data: careerDetails } = useQuery(
    "careerDetails",
    getCareerDetailsData,
    { cacheTime: 0 }
  );
  const careerDetailsData = careerDetails;
  const jobListData = jobList;

  if (isLoading) return <div>Fetching posts...</div>;
  // if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Helmet>
        <title>{SITETITLE} :: Career</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      {careerDeailsdata ? (
        <HeaderBanner
          imgUrl={careerDeailsdata.imgUrl}
          subtitle={careerDeailsdata.subtitle}
          title={careerDeailsdata.title}
          description={careerDeailsdata.description}
        />
      ) : (
        ""
      )}
      <JobProfiles data={jobListData} />
      <CareerRelatedKnowledge data={careerDetailsData} />
    </>
  );
};

export default CareerDetails;
